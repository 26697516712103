import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import App from './App';


import ContextWraper, { TextColor, IThemeState } from './context';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
<React.StrictMode>
	<BrowserRouter>
		<Routes>
			<Route path="/*" element={
			<App />} />
		</Routes>
	</BrowserRouter>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();